interface HttpRequestParams {
  url: string;
  method: string;
  body?: Record<string, any>;
  callback: (response: string) => void;
  onError?: (response?: string, status?: number) => void;
  withCredentials?: boolean;
  postJSON?: boolean;
  headers?: Record<string, string>;
}

export const httpRequest = ({
  url,
  method,
  body,
  callback,
  onError,
  withCredentials = false,
  postJSON = false,
  headers,
}: HttpRequestParams): void => {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function (): void {
    if (xmlHttp.readyState == 4 && xmlHttp.status == 200) callback(xmlHttp.responseText);
    if (xmlHttp.readyState == 4 && (xmlHttp.status < 200 || xmlHttp.status >= 300)) {
      onError && onError(xmlHttp.responseText, xmlHttp.status);
    }
  };
  xmlHttp.open(method, url, true); // true for asynchronous
  if (method === 'POST') {
    const contentType = postJSON ? 'application/json' : 'application/x-www-form-urlencoded';
    xmlHttp.setRequestHeader('Content-Type', contentType);
    if (headers) {
      Object.entries(headers).forEach(([key, value]) => {
        xmlHttp.setRequestHeader(key, value);
      });
    }
  }
  const bodyString = postJSON
    ? JSON.stringify(body ?? {})
    : body
      ? new URLSearchParams(body).toString()
      : null;
  xmlHttp.withCredentials = withCredentials;
  xmlHttp.send(bodyString);
};
